<p class="">
  You are updating preferences for the account associated with
  {{ userEmail | privacyMask : "email" }},
  {{ userPhoneNumber | privacyMask : "phone" }}
</p>

<div class="updates-container">
  <hfc-updates-container
    [isEssential]="true"
    [updatesTitle]="'Important Updates'"
    (importantUpdatesChanged)="onImportantUpdatesChanged($event)"
  ></hfc-updates-container>
  <hfc-updates-container
    [isEssential]="false"
    [updatesTitle]="'Marketing & Optional Updates'"
    (optionalUpdatesChanged)="onOptionalUpdatesChanged($event)"
  ></hfc-updates-container>
</div>

<ng-container *ngIf="isStandalone">
  <footer class="contact-preference_footer">
    <div class="paperless-container">
      <hfc-button type="primary" (click)="updateContactPreferences()"
        >Update preferences</hfc-button
      >
      <hfc-paperless-graphic
        [isPaperless]="isPaperless"
      ></hfc-paperless-graphic>
    </div>

    <p class="privacy-policy">
      See our
      <a
        href="https://heronfinancial.co.uk/privacy-policy/"
        class="link-bold"
        target="_blank"
        >Privacy policy</a
      >
      to learn how we process your data
    </p>
  </footer>
</ng-container>
