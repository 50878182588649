import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "hfc-updates-container",
  templateUrl: "./updates-container.component.html",
  styleUrls: ["./updates-container.component.scss"],
})
export class UpdatesContainerComponent implements OnInit {
  @Input() isEssential: boolean = false;
  @Input() updatesTitle: string = "";
  @Output() importantUpdatesChanged = new EventEmitter<any>();
  @Output() optionalUpdatesChanged = new EventEmitter<any>();

  public isLoading: boolean = false;
  public updatesForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.isEssential) {
      this.updatesForm = this.fb.group({
        importantMarketingUpdates: this.fb.group({
          email: [{ value: true, disabled: true }],
          phone: [{ value: true, disabled: true }],
          whatsapp: [false],
          post: [false],
          note: [""],
        }),
      });

      this.updatesForm.valueChanges
        .pipe(debounceTime(200))
        .subscribe((value) => {
          this.importantUpdatesChanged.emit(value.importantMarketingUpdates);
        });
    } else {
      this.updatesForm = this.fb.group({
        optionalMarketingUpdates: this.fb.group({
          mortgageMarketEmailUpdate: [false],
          emailOptional: [false],
          phoneOptional: [false],
          whatsappOptional: [false],
          postOptional: [false],
        }),
      });

      this.updatesForm.valueChanges
        .pipe(debounceTime(200))
        .subscribe((value) => {
          this.optionalUpdatesChanged.emit(value.optionalMarketingUpdates);
        });
    }
  }

  importantControl(name: string) {
    return this.updatesForm.get(["importantMarketingUpdates", name]);
  }

  optionalControl(name: string) {
    return this.updatesForm.get(["optionalMarketingUpdates", name]);
  }

  public get isDisabled() {
    return (
      this.importantControl("email").status === "DISABLED" ||
      this.importantControl("phone").status === "DISABLED"
    );
  }

  unsubscribe() {
    this.updatesForm.get("optionalMarketingUpdates").patchValue({
      mortgageMarketEmailUpdate: false,
      emailOptional: false,
      phoneOptional: false,
      whatsappOptional: false,
      postOptional: false,
    });
  }
}
