<hfc-spinner *ngIf="isLoading"></hfc-spinner>

<form *ngIf="!isLoading" [formGroup]="updatesForm" class="content">
  <section class="update-container">
    <div class="update-container_header">
      <h2>{{ updatesTitle }}</h2>
    </div>
    <ng-container *ngIf="isEssential; else optionalUpdateTemplate">
      <p class="update-container_row helpful-text">
        Necessary updates on your case
      </p>
      <div class="update-container_row">
        <hf-custom-checkbox
          label="Email (Required)"
          [checkboxFormControl]="importantControl('email')"
          [isFormGroup]="true"
          [isDisabled]="isDisabled"
        ></hf-custom-checkbox>
      </div>
      <div class="update-container_row">
        <hf-custom-checkbox
          label="Phone (Required)"
          [checkboxFormControl]="importantControl('phone')"
          [isFormGroup]="true"
          [isDisabled]="isDisabled"
        ></hf-custom-checkbox>
      </div>
      <div class="update-container_row">
        <hf-custom-checkbox
          label="Whatsapp"
          [checkboxFormControl]="importantControl('whatsapp')"
          [isDisabled]="false"
          [isFormGroup]="true"
        ></hf-custom-checkbox>
      </div>
      <div class="update-container_row">
        <hf-custom-checkbox
          label="Post"
          [checkboxFormControl]="importantControl('post')"
          [isDisabled]="false"
          [isFormGroup]="true"
        ></hf-custom-checkbox>
      </div>
      <div class="update-container_column">
        <p class="helpful-text">Preference Note (optional)</p>
        <textarea
          placeholder="Ie specific times you do not wish to be called"
          [formControl]="importantControl('note')"
        ></textarea>
      </div>
    </ng-container>
    <ng-template #optionalUpdateTemplate>
      <div class="update-container_row mortgage-market-row">
        <hf-custom-checkbox
          label="Mortgage market update email"
          [checkboxFormControl]="optionalControl('mortgageMarketEmailUpdate')"
          [isDisabled]="false"
          [isFormGroup]="true"
        ></hf-custom-checkbox>
        <span></span>
      </div>
      <span class="border-bottom"></span>
      <p class="update-container_row helpful-text">
        Additionally, I’m happy to be contacted regarding helpful information
        and offers
      </p>
      <div class="update-container_row">
        <hf-custom-checkbox
          label="Email"
          [checkboxFormControl]="optionalControl('emailOptional')"
          [isFormGroup]="true"
          [isDisabled]="false"
        ></hf-custom-checkbox>
      </div>
      <div class="update-container_row">
        <hf-custom-checkbox
          label="Phone"
          [checkboxFormControl]="optionalControl('phoneOptional')"
          [isDisabled]="false"
          [isFormGroup]="true"
        ></hf-custom-checkbox>
      </div>
      <div class="update-container_row">
        <hf-custom-checkbox
          label="Whatsapp"
          [checkboxFormControl]="optionalControl('whatsappOptional')"
          [isDisabled]="false"
          [isFormGroup]="true"
        ></hf-custom-checkbox>
      </div>
      <div class="update-container_row">
        <hf-custom-checkbox
          label="Post"
          [checkboxFormControl]="optionalControl('postOptional')"
          [isDisabled]="false"
          [isFormGroup]="true"
        ></hf-custom-checkbox>
      </div>
      <div class="update-container_row">
        <span (click)="unsubscribe()" class="unsubscribe-link"
          >Unsubscribe</span
        >
      </div>
    </ng-template>
  </section>
</form>
