import { Component, Input } from "@angular/core";

@Component({
  selector: "hfc-paperless-graphic",
  templateUrl: "./paperless-graphic.component.html",
  styleUrls: ["./paperless-graphic.component.scss"],
})
export class PaperlessGraphicComponent {
  @Input() isPaperless: boolean = true;
  constructor() {}

  getPaperlessGraphicText() {
    if (this.isPaperless) {
      return "You’ve gone paperless!";
    } else return "Go paperless";
  }

  getPaperlessGraphicSVG() {
    if (this.isPaperless) {
      return `assets/svg/sprite.svg#paperless`;
    } else return `assets/svg/sprite.svg#mail`;
  }
}
