import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxsModule } from "@ngxs/store";
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";

import { environment } from "../environments/environment";
import { CanAccessGuard } from "./app-can-access-guard.routing";
import { AppCanDeactivateGuardRouting } from "./app-can-deactivate-guard.routing";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HfChangePasswordModule } from "./website/modules/dashboard/change-password/hf-change-password.module";
import { Environment } from "./website/services/environment.service";
import { EvergladesEnvironment } from "./website/services/everglades-environment.service";
import { ServicesModule } from "./website/services/services.module";
import { TemplateService } from "./website/services/template.service";
import { ToasterService } from "./website/services/toaster.service";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { TaskModule } from "./website/modules/task/task.module";
import { DashboardModule } from "./website/modules/dashboard/dashboard.module";
import { MortgagesModule } from "./website/modules/mortgages/mortgages.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { CanAccessNeedsVerifyGuard } from "./app-can-access-needs-verify-guard.routing";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";

const ngxsLogger = false;

const imports = ngxsLogger ? [NgxsLoggerPluginModule.forRoot()] : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsRouterPluginModule.forRoot(),
    ...imports,
    BrowserModule,
    BrowserAnimationsModule,
    ServicesModule,
    AppRoutingModule,
    HfChangePasswordModule,
    DashboardModule,
    MortgagesModule,
    TaskModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.serviceWorkerEnabled,
    }),
  ],
  providers: [
    CanAccessGuard,
    CanAccessNeedsVerifyGuard,
    AppCanDeactivateGuardRouting,
    ServicesModule,
    TemplateService,
    ToasterService,
    { provide: Environment, useClass: EvergladesEnvironment },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
